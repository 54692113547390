<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{singular}} #{{data.id}} <span v-if="data.poId">for Purchase Order #{{data.poId}}</span></h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              class="ml-2"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <span v-if="data.createdAt">Created By {{lookupUsername(data.createdBy)}} at {{utils.formatDate(data.createdAt, 'withTime')}} from {{ lookupBranch(data.branchId) }}</span>
          <span v-if="data.sentAt">Sent Out By {{lookupUsername(data.sentBy)}} at {{utils.formatDate(data.sentAt, 'withTime')}}</span>
          <span v-if="data.receivedAt">Received By {{lookupUsername(data.receivedBy)}} at {{utils.formatDate(data.receivedAt, 'withTime')}}</span>
          <span v-if="data.voidedAt">Voided By {{lookupUsername(data.voidedBy)}} at {{utils.formatDate(data.voidedAt, 'withTime')}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-btn color="error" @click="openVoidDialog()" :disabled="data.status===-1">Void</v-btn>
            <v-btn v-if="getAllowedBranches.findIndex(x => x.id===data.branchId)>=0 && data.status>=0" class="ml-2" color="success" @click="openSendOutDialog()" :disabled="!computedAllowSend">{{ data.status===1?'Sent':'Send Out' }}</v-btn>
            <v-btn v-if="getAllowedBranches.findIndex(x => x.id===data.locationTo)>=0 && data.status>=1" class="ml-2" color="success" @click="openReceiveInDialog()" :disabled="!computedAllowReceive">{{ data.status===2?'Received':'Receive' }}</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-2">
          <span>
            <v-autocomplete
                :items="getBranches.filter(x => getAllowedBranches.findIndex(y => y.id===x.id)>=0)"
                v-model="data.locationFrom"
                @change="update"
                item-text="name"
                item-value="id"
                :disabled="!canEdit || data.status!==0"
                outlined
                label="Transfer From"
                clearable
            />
          </span>
          <span>
            <v-autocomplete
                :items="getBranches.filter(x => x.id != data.locationFrom)"
                v-model="data.locationTo"
                @change="update"
                item-text="name"
                item-value="id"
                :disabled="!canEdit || data.status!==0 || !data.locationFrom"
                outlined
                label="Transfer To"
                clearable
            />
          </span>
          <span v-if="canEdit" class="d-flex flex-column">
            <h2>Add Item</h2>
            <v-card outlined>
              <v-card-text>
                <span class="d-flex flex-column">
                  <v-text-field :loading="searchLoading" clearable label="Search Products" v-model="productSearchTerm" dense outlined @change="searchProduct"></v-text-field>
                </span>
                <span>
                  <span v-if="productSearchResults.length>0" class="d-flex flex-row justify-center">
                    <b>Search Results</b>
                    <v-btn x-small color="info" style="margin-left: 10px;" @click="clearSearch">Clear</v-btn>
                  </span>
                  <div v-for="(item, i) in productSearchResults" :key="i">
                    <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                      <span class="d-flex flex-row justify-space-between">
                        <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                        <v-btn :disabled="data.InventoryTransferLineItems.findIndex(x => x.productId===item.id)>=0" :loading="item.loading" x-small fab color="success" @click="addProductToIT(item)"><v-icon>mdi-plus</v-icon></v-btn>
                      </span>
                      <span class="d-flex flex-column align-start">
                        <span>ID: {{item.id}}, SKU:{{item.sku}}</span>
                      </span>
<!--                      <span class="d-flex flex-column align-start">-->
<!--                        <span>Type: {{item.type}}</span>-->
<!--                      </span>-->
                    </div>
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
        <v-col style="background-color: rgba(0,0,0,0.05); border-radius: 10px; padding: 20px;">
          <div style="height: 100%;">
            <h2>Line Items</h2>
            <div style="border: 2px solid rgba(0,0,0,0.15); border-radius: 10px; margin-top: 20px;">
              <!-- body -->
                <v-simple-table style="background-color: rgba(0,0,0,0)">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        ID
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-center">
                        SKU
                      </th>
                      <th class="text-left">
                        QTY TO SEND
                      </th>
                      <th class="text-left">
                        QTY RECVD
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in data.InventoryTransferLineItems" :style="i===data.InventoryTransferLineItems.length-1?'border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;':''" :key="i">
                      <td class="text-center">{{item.productId}}</td>
                      <td>{{item.productName}}</td>
                      <td class="text-center">{{item.sku}}</td>
                      <td>
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="border-bottom-left-radius: inherit;"
                            dense
                            type="number"
                            :disabled="!canEdit"
                            :rules="quantityRules"
                            v-model="item.qtyToSend"
                            min="1"
                            @change="updateLineItem(item.productId)">
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="border-bottom-left-radius: inherit;"
                            dense
                            type="number"
                            :disabled="!canEditReceived"
                            :rules="quantityRules"
                            v-model="item.qtyReceived"
                            min="0"
                            @change="updateLineItem(item.productId)">
                        </v-text-field>
                      </td>
                      <td style="border-bottom-right-radius: inherit;">
                        <v-btn v-if="canEdit" :loading="item.loading" small fab color="error" @click="removeLineItem(item.productId)"><v-icon >mdi-delete</v-icon></v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </v-col>
        <v-col cols="2">
          <span class="d-flex flex-column ml-3">
            <v-card outlined>
              <v-card-text>
                <v-textarea
                    label="Notes"
                    v-model="data.metadata.notes"
                    @change="updateNote"
                    outlined dense
                />
                <v-btn small color="success">Save</v-btn>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
        v-model="authDialog.sendOutOpen"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Send Out {{singular}}
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="authDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="closeAuthDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :loading="this.authDialog.loading"
              :disabled="!this.authDialog.password"
              @click="attemptSendOut"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
    <v-dialog
        v-model="authDialog.receiveInOpen"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Receive In {{singular}}
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="authDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="closeAuthDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :loading="this.authDialog.loading"
              :disabled="!this.authDialog.password"
              @click="attemptReceiveIn"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="authDialog.voidOpen"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Void {{singular}}
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="authDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="closeAuthDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :loading="this.authDialog.loading"
              :disabled="!this.authDialog.password"
              @click="attemptVoid"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import utils from "../../plugins/helpers";
  import {mapGetters} from 'vuex'
  export default {
    components: {
    },
    data () {
      return {
        utils: utils,
        loader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        quantityRules: [
          v => v>=1 || 'Must be more than or equal 1'
        ],
        priceRules: [
          v => v>=0 || 'Must be more than or equal 0'
        ],

        data: {
          InventoryTransferLineItems: [],
          metadata: {
          }
        },

        singular: "Inventory Transfer",
        singularLower: "inventorytransfer",
        plural: "Inventory Transfers",
        pluralLower: "inventorytransfers",

        authDialog: {
          sendOutOpen: false,
          receiveInOpen: false,
          voidOpen: false,
          password: '',
          loading: false,
        },

        productSearchResults: [],
        productSearchTerm: "",
        searchLoading: false
      }
    },
    async mounted(){
      console.log(this.getAllowedBranches, this.getBranches)

      await this.getAllData();
    },
    computed: {
      computedAllowSend(){
        if(this.data.status!==0) return false;
        if(!this.data.locationFrom) return false;
        if(!this.data.locationTo) return false;
        if(this.data.branchId!==this.getBranch) return false;
        if(!this.data.InventoryTransferLineItems || this.data.InventoryTransferLineItems.length===0) return false;
        for(let item of this.data.InventoryTransferLineItems){
          if(!item.qtyToSend || item.qtyToSend<=0 || isNaN(item.qtyToSend)) return false;
        }
        return true
      },
      computedAllowReceive(){
        if(this.data.status!==1) return false;
        if(this.data.locationTo!==this.getBranch) return false;
        if(!this.data.InventoryTransferLineItems || this.data.InventoryTransferLineItems.length===0) return false;
        for(let item of this.data.InventoryTransferLineItems){
          if(item.qtyReceived===undefined || item.qtyReceived===null || item.qtyReceived<0 || isNaN(item.qtyReceived)) return false;
        }
        return true
      },
      canEdit(){
        if(this.data.status===0 && this.data.branchId === this.getBranch) return true;
        return false;
      },
      canEditReceived(){
        if(this.data.status===1 && this.data.locationTo === this.getBranch) return true;
        return false;
      },
      ...mapGetters(['getWorkingId', 'getEndpoint', 'getGlobalValue', 'lookupUsername', 'isAllowed', 'getAllowedBranches', 'getBranches', 'getBranch', 'lookupBranch'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async update(){
        try{
          this.loader = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.data.id}`, this.data);
          if(res.data.error) throw res.data.error

          this.data = {
            ...this.data,
            ...res.data.data
          };
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      async updateNote(){
        try{
          this.loader = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/changeNote/${this.data.id}`, {data: this.data.metadata.notes});
          if(res.data.error) throw res.data.error
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      async addProductToIT(item){
        try{
          item.loading = true;

          let obj = {
            itId: this.data.id,
            productId: item.id,
            productName: item.name,
            sku: item.sku,
            qtyToSend: 1,
            qtyReceived: 0,
            metadata: {}
          }

          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/lineItem/${this.data.id}`, obj);
          if(res.data.error) throw res.data.error

          this.data.InventoryTransferLineItems.push({...res.data.data, loading: false});
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          item.loading = false;
        }
      },
      async removeLineItem(id){
        try{
          let item = this.data.InventoryTransferLineItems.find(x => x.productId===id);
          if(!item) throw "Product not in transfer";

          item.loading = true;
          this.data = {
            ...this.data,
            InventoryTransferLineItems: this.data.InventoryTransferLineItems
          }

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/deleteLineItem/${this.data.id}`, item);
          if(res.data.error) throw res.data.error

          let i = this.data.InventoryTransferLineItems.findIndex(x => x.productId===id);

          this.data.InventoryTransferLineItems.splice(i, 1);
        }
        catch (error) {
          let item = this.data.InventoryTransferLineItems.find(x => x.productId===id);

          item.loading = false;
          this.data = {
            ...this.data,
            InventoryTransferLineItems: this.data.InventoryTransferLineItems
          }

          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async updateLineItem(id){
        try{
          this.loader = true;

          let item = this.data.InventoryTransferLineItems.find(x => x.productId===id);
          if(!item) throw "Product not in transfer";
          if(!item.qtyToSend) item.qtyToSend = 0
          if(!item.qtyReceived) item.qtyReceived = 0

          if(item.qtyToSend<1) {
            throw "QTY TO SEND must be greater than 0."
          }
          
          if(this.data.status == 1 && item.qtyReceived<1) {
            throw "QTY RECVD must be greater than 0."
          }

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/lineItem/${this.data.id}`, item);
          if(res.data.error) throw res.data.error

          item = res.data.data;
          this.data = {
            ...this.data,
            InventoryTransferLineItems: this.data.InventoryTransferLineItems
          }
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.searchLoading = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?val=${encodeURIComponent(this.productSearchTerm)}`);
            if(res.data.error) throw res.data.error

            let products = res.data.data;

            for(let prod of products){

              prod.loading = false;
            }
            this.productSearchResults = products

            if(products.length===0) throw "No products matching the search criteria."
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally {
          this.searchLoading = false;
        }
      },
      clearSearch(){
        this.productSearchResults = [];
      },
      openSendOutDialog(){
        this.authDialog.voidOpen = false;
        this.authDialog.sendOutOpen = true;
        this.authDialog.receiveInOpen = false;
        this.authDialog.loading = false
        this.authDialog.password = ''
      },
      openReceiveInDialog(){
        this.authDialog.voidOpen = false;
        this.authDialog.sendOutOpen = false;
        this.authDialog.receiveInOpen = true;
        this.authDialog.loading = false
        this.authDialog.password = ''
      },
      openVoidDialog(){
        this.authDialog.voidOpen = true;
        this.authDialog.sendOutOpen = false;
        this.authDialog.receiveInOpen = false;
        this.authDialog.loading = false
        this.authDialog.password = ''
      },
      closeAuthDialog(){
        this.authDialog.voidOpen = false;
        this.authDialog.sendOutOpen = false;
        this.authDialog.receiveInOpen = false;
        this.authDialog.password = ''
      },
      async attemptVoid(){
        try{
          this.authDialog.loading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/void/${this.data.id}`, {...this.authDialog})
          if(res.data.error) throw res.data.error

          await this.getAllData();

          this.snack(`✅ Voided.`)
          this.closeAuthDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.authDialog.loading = false
        }
      },
      async attemptSendOut(){
        try{
          this.authDialog.loading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/sendOut/${this.data.id}`, {...this.authDialog})
          if(res.data.error) throw res.data.error

          await this.getAllData();

          this.snack(`✅ Sent Out.`)
          this.closeAuthDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.authDialog.loading = false
        }
      },
      async attemptReceiveIn(){
        try{
          this.authDialog.loading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/receiveIn/${this.data.id}`, {...this.authDialog})
          if(res.data.error) throw res.data.error

          await this.getAllData();

          this.snack(`✅ Received In.`)
          this.closeAuthDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.authDialog.loading = false
        }
      },
      async getAllData(){
        try{
          this.loader = true;

          let rr = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`);
          if(rr.data.error) throw rr.data.error
          this.data = rr.data.data

          this.data.InventoryTransferLineItems.map(x => {
            return {...x, loading: false}
          });
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.loader = false;
        }
      }
    }
  }
</script>
